import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbDateStruct, NgbDatepickerI18n, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from 'app/config/constants';
import { DateTime } from 'luxon';
import moment from 'moment';
import { CustomDatePickerI18n } from './custom-date-picker-i18n';
@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatePickerI18n }]
})
/**
 * Custom date time picker component based on ng-bootstrap date picker & time picker
 */
export class CustomDatePickerComponent implements OnChanges {
  // set to true when time is included
  @Input() public withTime: boolean;

  // set to true when in daily schedule pop up
  @Input() public width: String;

  // set to true when in daily schedule pop up
  @Input() public isClear: boolean;

  // set to true when in dialog pop up
  @Input() public inDialog: boolean;

  // input date time string
  @Input() public currentDateTime: Date;

  /**
   * is valid
   */
  @Input() public isValid: boolean;

  /**
   * left
   */
  @Input() public left: String;

  /**
   * margin left
   */
  @Input() public marginLeft: String;

  /**
   * has border
   */
  @Input() public hasBorder: boolean;

  /**
   * is scroll top
   */
  @Input() public isScrollTop: boolean = false;

  /**
   * Placeholder
   */
  @Input() public placeholder: String;

  @Input() public isDisable: boolean;

  @Input() public time: any;

  @Input() public formatDate: string;
  /**
   * date display
   */
  public dateDisplay: string;

  // current date struct
  public currentDateStruct: NgbDateStruct;

  // current time struct
  public currentTimeStruct: NgbTimeStruct;

  // event emitted when date or time is changed
  @Output() public currentDateChange = new EventEmitter<String>();
  @Output() public hasFocusInput = new EventEmitter<Boolean>();
  @Output() public timeOutPut = new EventEmitter<any>();

  private static readonly DATE_FORMAT = 'yyyy-MM-dd';
  currentDate = new Date();
  lastMinute: number;
  lastHour: number;

  date;
  readonly FORMAT_TIME = 'HH:mm';
  config: any = {
    format: this.FORMAT_TIME,
    unSelectOnClick: false,
    hideOnOutsideClick: true,
    showTwentyFourHours: true,
    minutesInterval: 10,
    hours24Format: 'HH'
  };
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.currentDate = this.currentDateTime ? this.currentDateTime : new Date();
    this.date = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
    this.dateDisplay = this.currentDateTime
      ? this.formatDate
        ? DateTime.fromObject(this.date).toFormat(this.formatDate)
        : DateTime.fromObject(this.date).toFormat('yyyy.MM.dd')
      : '';
    this.currentDateStruct = this.date;
    const tmpTime = moment(this.time)
      .format(Constant.FORMAT_TIME_TO_MINUTES)
      .split(':');
    this.lastHour = parseInt(tmpTime[0]);
    this.lastMinute = parseInt(tmpTime[1]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isClear']) {
      if (this.isClear) {
        this.dateDisplay = '';
        this.currentDateChange.emit('');
      }
    }
  }

  /**
   * choose a date value
   * @param selectedDate chosen date struct
   */
  chooseDate(selectedDate) {
    this.currentDateStruct = selectedDate;
    this.date = this.currentDateStruct;
    // this.currentDateTime = DateTime(this.currentDateTime as string).format('MMM. DD, YYYY');
    let dateTime = DateTime.fromObject(this.currentDateStruct);
    if (this.formatDate) {
      this.dateDisplay = dateTime.toFormat(this.formatDate);
    } else {
      this.dateDisplay = dateTime.toFormat('yyyy.MM.dd');
    }
    this.currentDateChange.emit(dateTime.toFormat(CustomDatePickerComponent.DATE_FORMAT));
  }

  /**
   * Check Focus Input
   */
  checkFocusInput(): void {
    this.hasFocusInput.emit(true);
  }

  testTime(data) {
    let tmpTime =
      typeof data == 'object'
        ? moment(data._d)
            .format(Constant.FORMAT_TIME_TO_MINUTES)
            .split(':')
        : data.split(':');
    let currentMinute = parseInt(tmpTime[1]);
    let currentHour = parseInt(tmpTime[0]);
    let newHour, newMinute;
    if (
      (currentHour < this.lastHour && currentMinute > this.lastMinute) ||
      (currentHour > this.lastHour && currentMinute < this.lastMinute) ||
      (currentHour == 23 && currentMinute == 50 && this.lastMinute == 0 && this.lastHour == 0) ||
      (currentHour == 0 && currentMinute == 0 && this.lastMinute == 50 && this.lastHour == 23)
    ) {
      newHour = this.lastHour;
      newMinute = currentMinute;
      this.time = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;
      this.timeOutPut.emit(this.time);
      this.cdr.detectChanges();
    } else {
      this.lastHour = currentHour;
      this.lastMinute = currentMinute;
      this.time = data;
      if (typeof this.time != 'string') {
        this.time = moment(this.time).format(Constant.FORMAT_TIME_TO_MINUTES);
      }
      this.timeOutPut.emit(this.time);
    }
  }
}
